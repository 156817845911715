// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/wRXSj3ky/0/studios-marketing/marvelstudiostermsofuse/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/builds/wRXSj3ky/0/studios-marketing/marvelstudiostermsofuse/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/builds/wRXSj3ky/0/studios-marketing/marvelstudiostermsofuse/.cache/data.json")

